/* ==========================================================================
   HELPERS
   ========================================================================== */

/// @group Helpers
/// @author Harry Roberts
/// @param {bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// @group Helpers
@mixin placeholder {
  // Chrome/Opera/Safari
  &::-webkit-input-placeholder {
    @content;
  }
  // Firefox 19+
  &::-moz-placeholder {
    @content;
  }
  // IE 10+
  &:-ms-input-placeholder {
    @content;
  }
  // Firefox 18-
  &:-moz-placeholder {
    @content;
  }
}

/* ==========================================================================
   CARD
   ========================================================================== */

.c-card {
  @include elevation(3);
  @include gradient-mask(black, 1);
  @include transition(default);
  display: block;
  padding: size(22) size(3) size(3);
  min-height: rem(315);
  text-decoration: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &, &:after {
    border-radius: rem(4);
  }

  @include on-event {
    @include elevation(4);
    cursor: pointer;

    .c-card__content {
      transform: translateY(rem(-4));
    }
  }

  @for $i from 1 through 9 {
    &:nth-of-type(#{$i}) {
      @include animation(intro, $i);
    }
  }
}



.c-card__tag {
  @include type-scale(-2);
  @include elevation(2);
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(size(3), -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: size(1/2) size(1);
  line-height: 1;
  border-radius: rem(2);
  background-color: black;
  color: white;
}



.c-card__content {
  @include transition(default);
}



.c-card__title,
.c-card__description {
  color: white;
  text-shadow: 0 0 rem(2) rgba(black, .9)
}



.c-card__title {
  @include type-scale(1);
  @include text-wrap(2);
  margin-bottom: size(1);
}



.c-card__description {
  @include type-scale(-1, 0);
  @include text-wrap(3);
  margin: 0;
  font-weight: 400;
}

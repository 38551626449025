/* ==========================================================================
   COLORS
   ========================================================================== */

/// @group Colors
/// @type map
$colors: (
  gray: (
    000: #f8f9fa,
    100: #f1f3f5,
    200: #e9ecef,
    300: #dee2e6,
    400: #ced4da,
    500: #adb5bd,
    600: #868e96,
    700: #495057,
    800: #343a40,
    900: #21252a,
  ),

  util: (
    success: #40c057,
    warning: #f59f00,
    danger: #f03e3e,
    info: #1c7ed6,
  )
);

/// @group Colors
/// @require {variable} $colors
/// @param {string} $name
/// @param {number} $shade [500]
/// @return {color}
/// @example scss
/// // hero.scss
/// .c-hero {
///  background-color: color(berry, 700);
/// }
///
/// // hero.css
/// .c-hero {
///  background-color: #cc163e;
/// }
@function color($name, $shade: 500) {
  @return map-get(map-get($colors, $name), $shade);
}
